import React from 'react';
import ReactDOM from 'react-dom';

import App from './app';
import { config } from './helpers';

window.langId = 'fr';

window.fetchPost = (serverName, path, body) => {
	const server = config(serverName);
	const params = {
		method: 'POST',
		...(typeof body === 'string'
			? {
					body: body,
			  }
			: {
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
					body: new URLSearchParams(body),
			  }),
	};
	return fetch(server + path, params);
};
window.fetchGet = (serverName, path) => {
	let server = config(serverName);
	return fetch(server + path);
};

window.textToLines = (text, props = {}) => {
	var lines = text.split(/\n/).map(line => {
		return (
			<div
				key={window.newKey()}
				className='line'
				dangerouslySetInnerHTML={{ __html: line.trim() }}
				{...props}
			/>
		);
	});
	return lines;
};

window.key = 0;
window.newKey = _ => {
	window.key += 1;
	return window.key.toString();
};

ReactDOM.render(<App />, document.getElementById('app'));
