import React, { Component } from 'react';
import './style.css';
import ReactDOM from 'react-dom';
import TextTitle from '../text-title';

class Text extends Component {
	constructor(props) {
		super(props);

		this.state = { visible: false };

		this.textContRef = React.createRef();

		this.lineClick = this.lineClick.bind(this);
		this.showTest = this.showTest.bind(this);
	}

	componentDidUpdate() {
		if (this.textContRef.current === null) {
			return;
		}

		this.selectLines();
	}

	setId(id) {
		window
			.fetchGet('brightwater', '/forms/' + id)
			.then(result => result.json())
			.then(result => {
				this.meta = result;

				window
					.fetchGet('brightwater', '/forms/' + id + '/segmentation')
					.then(result => result.text())
					.then(text => {
						this.textContCp = (
							<div id='text-container' ref={this.textContRef}>
								{window.textToLines(text, { onClick: this.lineClick })}
							</div>
						);
						this.startIndex = undefined;
						this.endIndex = undefined;
						this.selectedLines = [];
						this.show();
					});
			});
	}

	selectLines() {
		this.selectedLines = [];
		Array.prototype.forEach.call(
			this.textContRef.current.children,
			(line, index) => {
				if (
					index === this.startIndex ||
					index === this.endIndex ||
					(this.startIndex <= index && index <= this.endIndex)
				) {
					line.classList.add('selected');
					this.selectedLines.push(line);
				} else {
					line.classList.remove('selected');
				}
				if (this.startIndex === index || this.endIndex === index) {
					line.classList.add('delimiter');
				} else {
					line.classList.remove('delimiter');
				}
			},
		);
	}

	lineClick(e) {
		e.preventDefault();
		e.stopPropagation();

		var line = e.target;

		if (!e.shiftKey) {
			this.startIndex = Array.from(this.textContRef.current.children).indexOf(
				line,
			);
		} else {
			this.endIndex = Array.from(this.textContRef.current.children).indexOf(
				line,
			);
		}
		this.selectLines();
	}

	show() {
		this.setState({ visible: true });
	}

	hide() {
		this.setState({ visible: false });
	}

	showTest() {
		if (this.selectedLines.length === 0) {
			alert(
				'You must select lines for the test.\nUse click to select the first line and shift+click to select the last one.',
			);
			return;
		}

		var texts = [];
		this.selectedLines.forEach(line => {
			texts.push(line.textContent);
		});
		var text = texts.join('\n');

		this.hide();
		window.testCp.setText(text);
	}

	render() {
		if (!this.state.visible) {
			return '';
		}
		console.log(this.meta);

		ReactDOM.render(
			<TextTitle key={window.newKey()} meta={this.meta} />,
			document.getElementById('react-text-title'),
		);

		return (
			<section id='text'>
				<h1 className='sticky-top'>
					Texte
					<button onClick={this.showTest} className='btn btn-secondary'>
						Test →
					</button>
				</h1>
				<div className='container'>{this.textContCp}</div>
			</section>
		);
	}
}

export default Text;
