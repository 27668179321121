import React, { Component } from 'react';
import './style.css';

class Test extends Component {
	constructor(props) {
		super(props);

		this.state = { visible: false };

		this.testContRef = React.createRef();

		this.correct = this.correct.bind(this);
		this.showText = this.showText.bind(this);
	}

	setText(text) {
		window
			.fetchPost('brightwater', '/test?language=' + window.langId, text)
			.then(result => result.json())
			.then(holes => {
				var test = '';
				var pos = 0;
				holes
					.sort((hole1, hole2) => {
						return hole1['span'][0] - hole2['span'][0];
					})
					.forEach(hole => {
						test += text.slice(pos, hole['span'][0]);
						if ('distractors' in hole && hole['distractors'].length) {
							var propositions = [hole['answer'], ...hole['distractors']].sort(
								_ => {
									return 0.5 - Math.random();
								},
							);
							test +=
								'<select class="answer input" data-answer="' +
								hole['answer'] +
								'">';
							test += '<option value=" " selected></option>';
							propositions.forEach(proposition => {
								test +=
									'<option value="' +
									proposition +
									'">' +
									proposition +
									'</option>';
							});
							test += '</select>';
						} else {
							test +=
								'<input class="answer input" data-answer="' +
								hole['answer'] +
								'" />';
						}
						pos = hole['span'][1];
					});
				test += text.slice(pos);

				this.testContCp = (
					<div id='test-container' ref={this.testContRef}>
						{window.textToLines(test)}
					</div>
				);
				this.show();
			});
	}

	correct(e) {
		this.testContRef.current
			.querySelectorAll('.answer.input')
			.forEach(answerEl => {
				var answer = answerEl.value;
				var trueAnswer = answerEl.dataset.answer;
				var correctnessClass = (answer === trueAnswer).toString();
				answerEl.outerHTML =
					'<div class="answer ' +
					correctnessClass +
					'">' +
					(answer !== trueAnswer ? '<span>' + answer + '</span> ' : '') +
					trueAnswer +
					'</div>';
			});
		e.target.disabled = true;
	}

	show() {
		this.setState({ visible: true });
	}

	hide() {
		this.setState({ visible: false });
	}

	showText() {
		this.hide();
		window.textCp.show();
	}

	render() {
		if (!this.state.visible) {
			return '';
		}

		return (
			<section id='test'>
				<h1 className='sticky-top'>
					Test
					<button onClick={this.showText} className='btn btn-secondary'>
						← Texte
					</button>
				</h1>
				<div className='container'>{this.testContCp}</div>
				<button className='btn btn-primary' onClick={this.correct}>
					Corriger
				</button>
			</section>
		);
	}
}

export default Test;
