import React, { Component } from 'react';
import './style.css';

class SearchForm extends Component {
	search = e => {
		var query = e.target.value;
		window
			.fetchPost('brightwater', '/forms/search?language=' + window.langId, {
				query: query,
			})
			.then(result => result.json())
			.then(results => {
				results = results.slice(0, 5);
				window.searchResultsCp.setResults(results);
			})
			.catch(_ => {
				window.searchResultsCp.setResults([]);
			});
	};

	render = () => {
		this.input = (
			<input
				className='form-control'
				id='book-title'
				placeholder='Titre du livre'
				autoComplete='off'
				onChange={this.search}
				onFocus={_ => window.searchResultsCp.show()}
			/>
		);
		return (
			<form id='search-form' onSubmit={e => e.preventDefault()}>
				<div className='form-group'>{this.input}</div>
			</form>
		);
	};
}

export default SearchForm;
