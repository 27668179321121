import React, { Component } from 'react';
import './style.css';

class TextTitle extends Component {
	render() {
		return (
			<div
				className='text-title'
				data-id={this.props.meta.id}
				dangerouslySetInnerHTML={{ __html: this.props.meta.title }}
			/>
		);
	}
}

export default TextTitle;
