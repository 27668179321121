import React, { Component } from 'react';
import './style.css';
import SearchResult from '../search-result';

class SearchResults extends Component {
	constructor(props) {
		super(props);

		this.state = { results: [] };
	}

	setResults(results) {
		this.setState({ results: results });
	}

	show() {
		this.setState({ visible: true });
	}

	hide() {
		this.setState({ visible: false });
	}

	render() {
		if (!this.state.visible) {
			return '';
		}

		return (
			<div id='search-results'>
				{this.state.results.map(result => {
					return <SearchResult key={window.newKey()} result={result} />;
				})}
			</div>
		);
	}
}

export default SearchResults;
