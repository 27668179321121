import React, { Component } from 'react';
import './style.css';
import TextTitle from '../text-title';

class SearchResult extends Component {
	constructor(props) {
		super(props);

		this.click = this.click.bind(this);
	}

	click() {
		window.searchResultsCp.hide();
		window.textCp.setId(this.props.result.id);
	}

	render() {
		return (
			<div className='search-result' onClick={this.click}>
				<TextTitle meta={this.props.result} />
			</div>
		);
	}
}

export default SearchResult;
