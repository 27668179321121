import React, { Component } from 'react';
import './bootstrap.css';
import './style.css';
import Language from '../components/language';
import Search from '../components/search';
import Text from '../components/text';
import Test from '../components/test';

class App extends Component {
	render() {
		return [
			<header>
				<div className='container'>
					<div className='row'>
						<div className='col-2'>
							<Language />
						</div>
						<div className='col'>
							<Search />
						</div>
						<div className='col-5' id='react-text-title'></div>
					</div>
				</div>
			</header>,
			<Text ref={cp => (window.textCp = cp)} />,
			<Test ref={cp => (window.testCp = cp)} />,
		];
	}
}

export default App;
