import React, { Component } from 'react';
import './style.css';
import ClickOutside from 'react-click-outside';
import SearchForm from '../search-form';
import SearchResults from '../search-results';

class Search extends Component {
	render() {
		return (
			<ClickOutside onClickOutside={() => window.searchResultsCp.hide()}>
				<div id='search' tabIndex='0'>
					<SearchForm />
					<SearchResults ref={cp => (window.searchResultsCp = cp)} />
				</div>
			</ClickOutside>
		);
	}
}

export default Search;
