import React, { Component } from 'react';

class Language extends Component {
	languages = {
		en: 'English',
		fr: 'Français',
	};

	change(e) {
		window.langId = e.target.value;
	}

	render() {
		return (
			<select onChange={this.change} defaultValue={window.langId}>
				{Object.entries(this.languages).map(([id, name]) => {
					return (
						<option key={window.newKey()} value={id}>
							{name}
						</option>
					);
				})}
			</select>
		);
	}
}

export default Language;
